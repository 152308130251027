<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <tablePoiCategory
        :poiContegory="poiContegory"
        @refresh="refresh"
        @editForm="editForm"
      />
    </div>
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
    <ModalPoiCategoryCreate @refresh="refresh" />
    <ModalPoiCategoryEdit @refresh="refresh" :id="id" />
  </div>
</template>

<script>
import tablePoiCategory from "@/views/component/Table/tablePoiCategory.vue";
import ModalPoiCategoryCreate from "@/views/component/Modal/ModalPoiCategory/ModalPoiCategoryCreate.vue";
import ModalPoiCategoryEdit from "@/views/component/Modal/ModalPoiCategory/ModalPoiCategoryEdit.vue";
export default {
  components: {
    tablePoiCategory,
    ModalPoiCategoryCreate,
    ModalPoiCategoryEdit,
  },
  data() {
    return {
      showPreloader: false,
      poiContegory: [],
      id: null,
      editPoiCategorySet: null,
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Название" },
      ],
    };
  },
  mounted() {
    this.openFilter();
  },
  methods: {
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.poiContegory = res.data;
          this.$store.commit("pageData/setdataCount", this.poiContegory.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    sendToParent(tableData) {
      this.poiContegory = tableData;
      this.$store.commit("pageData/setdataCount", this.poiContegory.length);
    },
    refresh() {
      let params = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      this.showPreloader = true;
      this.$http.get(`${this.$route.name}`, { params: params }).then((res) => {
        this.poiContegory = res.data;
        this.showPreloader = false;
        this.$store.commit("pageData/setdataCount", this.poiContegory.length);
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    editForm(id) {
      this.id = id;
      this.editPoiCategorySet = setTimeout(() => {
        this.$bvModal.show("editPoiCategory");
      });
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData() {
      this.refresh();
    },
  },
  destroy() {
    (this.poiContegory = []),
      (this.id = null),
      (this.editPoiCategorySet = null);
  },
};
</script>

<style lang="scss" scoped></style>
